<template>
  <v-app id="login-home">
    <v-main :dark="true">
      <v-container fill-height fluid dark>
        <v-layout align-center justify-center dark>
          <v-flex xs12 sm8 md4 dark>
            <v-form v-model="isValid">
              <v-card class="elevation-12">
                <v-toolbar color="secondary" light>
                  <v-toolbar-title>Sign in to your account</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    ref="username"
                    v-model="username"
                    :rules="[() => !!username || 'This field is required']"
                    prepend-icon="mdi-account"
                    label="Login"
                    name="login"
                    placeholder="Dupont,Marcel"
                    autocomplete="username"
                    required
                  />
                  <v-text-field
                    ref="password"
                    v-model="password"
                    :rules="[() => !!password || 'This field is required']"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    name="password"
                    placeholder="*********"
                    autocomplete="current-password"
                    counter
                    requireddark
                    @keydown.enter="login"
                    @click:append="showPassword = !showPassword"
                  />
                  <v-select
                    ref="clientId"
                    v-model="clientId"
                    :items="clientProfiles"
                    prepend-icon="mdi-lock"
                    placeholder="default (v1)"
                    autocomplete="clientId"
                    menu-props="auto"
                    hide-details
                    item-text="name"
                    item-value="id"
                    label="Select your access level"
                    required
                    dark
                    @keydown.enter="login"
                  ></v-select>
                </v-card-text>
                <v-divider class="mt-5" />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    align-center
                    justify-center
                    color="deep-purple accent-4"
                    block
                    :disabled="!isValid"
                    @click="login"
                    >Login
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationMixin from '@/mixins/notification-base';

export default {
  mixins: [NotificationMixin],
  data: () => ({
    username: '',
    password: '',
    clientId: '',
    clientProfiles: [],
    errorMessages: '',
    showPassword: false,
    isValid: false,
  }),
  created() {
    this.$vuetify.theme.dark = true;
    this.clients().then((clients) => {
      this.clientProfiles = clients
        .filter(c => (!this.options.profileAdmin && c.name.indexOf('admin') === -1) || this.options.profileAdmin)
        .map(c => ({
          id: c.id,
          name: this.profilteName(c.name),
        }));
    });
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      const { redirect } = this.$route.query;
      if (redirect) {
        this.$router.push(redirect);
      } else {
        this.$router.push({ name: 'dashboard' });
      }
    }
  },
  computed: {
    options() {
      return this.$store.getters['settings/theagent'];
    },
  },
  methods: {
    ...mapActions('app', ['clients', 'status']),
    profilteName(name) {
      const [, appVersion, appProfile] = name.split('.');
      return `${appProfile} (${appVersion})`;
    },
    login: function login() {
      // Initialize the form data
      this.$auth
        .loginWithRedirect({
          username: this.username,
          password: this.password,
          clientId: this.clientId || null,
        })
        .then(() => {
          const { redirect } = this.$route.query;
          if (redirect) {
            this.$router.push(redirect);
          } else {
            this.$router.push({ name: 'dashboard' });
          }
          this.notificationSuccess({
            title: 'Login success',
            text: 'Authenticated',
          });
        })
        // need to set $i18n locale for all translation from babel json
        .then(() => this.status().then(({ user }) => {
          this.$i18n.locale = user.preferences.lang || 'en';
          this.$store.commit('app/set_lang', user.preferences.lang || 'en');
        }))
        .catch((response) => {
          this.notificationError({
            title: 'Login Failed',
            text: response.message,
          });
        });
    },
  },
};
</script>

<style></style>
